import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Raleway\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/whousesai.com/next.js/src/components/Header.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/src/styles/globals.scss");
