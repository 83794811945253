"use client";
import Image from "next/image";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";

const navigation = [
  {
    href: "/about-us",
    name: "About",
  },
  {
    //href: "/category/Profession",
    name: "Profession",
    submenus: [
      { href: "/category/AI For Writing", name: "Writer" },
      { href: "/category/AI For Financial Services", name: "Financial Expert" },
      { href: "/category/AI For Doctors", name: "Doctor" },
      { href: "/category/AI For Agriculture", name: "Farmer" },
      { href: "/category/AI For Accountants", name: "Accountant" },
      { href: "/category/AI For Coding​", name: "Coding" },
      { href: "/category/AI For Teachers", name: "Teachers" },
      { href: "/category/AI For SEO", name: "SEO" },
      { href: "/category/AI For Trading Stocks", name: "Trading Stocks" },
      { href: "/category/AI For Website Testing", name: "Website Testing" },
      { href: "/category/AI For Business​", name: "Business​" },
      { href: "/category/AI For Education", name: "Education" },
      { href: "/category/AI For Images​", name: "Images​" },
      { href: "/category/AI For Healthcare​", name: "Healthcare​" },
      { href: "/category/AI For Lawyers", name: "Lawyers" },
      { href: "/category/AI For Designers", name: "Designers" },
      { href: "/category/AI For Websites", name: "Websites" },
      { href: "/category/AI For Legal Documents", name: "Legal Documents" },
      { href: "/category/AI For Gaming", name: "Gaming" },
    ],
  },
  {
    //href: "/category/Industry",
    name: "Industry",
    submenus: [
      { href: "/category/AI in Education", name: "Education" },
      { href: "/category/AI in Finance", name: "Finance" },
      { href: "/category/AI in Healthcare", name: "Healthcare" },
      { href: "/category/AI in Agriculture", name: "Agriculture" },
      { href: "/category/AI in Accounting", name: "Accounting" },
      { href: "/category/AI in Construction", name: "Construction" },
      { href: "/category/AI in Automotive", name: "Automotive" },
      { href: "/category/AI in Aviation", name: "Aviation" },
      { href: "/category/AI in Art", name: "Art" },
      {
        href: "/category/AI in Customer Experience",
        name: "Customer Experience",
      },
      {
        href: "/category/AI in Hospitality",
        name: "Hospitality",
      },
      {
        href: "/category/AI in Legal",
        name: "Hospitality",
      },
      {
        href: "/category/AI in Entertainment",
        name: "Entertainment",
      },
      {
        href: "/category/AI in Insurance",
        name: "Insurance",
      },
      {
        href: "/category/AI in Content Generation",
        name: "Content Generation",
      },
    ],
  },
  {
    //href: "/category/Industry",
    name: "Tools",
    submenus: [
      {
        href: "/category/AI Tools for Business",
        name: "Business",
      },
      {
        href: "/category/AI Tools for Accounting",
        name: "Business",
      },
      {
        href: "/category/AI Tools for Writing",
        name: "Writing",
      },
      { href: "/category/AI Tools For Teachers", name: "Teachers" },
      { href: "/category/AI Tools For Education", name: "Education" },
      { href: "/category/AI Tools For Lawsuit", name: "Lawsuit" },
      { href: "/category/AI Tools for Art", name: "Art" },
      { href: "/category/AI Tools for Healthcare", name: "Healthcare" },
      {
        href: "/category/AI Tools for Website SEO",
        name: "Website SEO",
      },
      {
        href: "/category/AI Tools for Law Enforcement",
        name: "Law Enforcement",
      },
      {
        href: "/category/AI Tools for Marketing",
        name: "Marketing",
      },
      {
        href: "/category/AI Tools for Research",
        name: "Research",
      },
      {
        href: "/category/AI Tools for Career",
        name: "Career",
      },
    ],
  },
  { href: "/blog", name: "Blog" },
  { href: "/contact-us", name: "Contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header
      className={`top-0 w-full transition-transform duration-300 tracking-wider z-30 ${
        isScrollingUp
          ? "transform translate-y-0"
          : "transform -translate-y-full"
      }`}
    >
      <Disclosure as="nav" className="bg-white z-30">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-xl mx-auto">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 w-96">
                    <Link
                      href="/"
                      className="flex flex-row items-center justify-center"
                    >
                      <Image
                        src="/images/logo-whousesai.png"
                        alt="Prateeksha Web Design logo"
                        width={100}
                        height={100}
                        quality={75}
                        sizes="(max-width: 600px) 50vw, (min-width: 601px) 25vw"
                      />
                      <div className="text-4xl font-bold text-colorBlueGray-800">
                        Who Uses AI
                      </div>
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex space-x-6 justify-end">
                      {navigation.map((item, index) => {
                        return item.submenus ? (
                          <div
                            key={item.name}
                            className="relative submenu z-30"
                          >
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black px-1 py-2 rounded-md text-lg font-medium flex items-center z-40"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-1 h-4 w-4 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute bg-neutral-200 shadow-lg rounded-md py-2 mt-1 w-56 z-40">
                                {item.submenus.map((subitem) => {
                                  return (
                                    <Link
                                      key={subitem.name}
                                      href={subitem.href}
                                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                      onClick={() => handleLinkClick(close)}
                                    >
                                      {subitem.name}
                                    </Link>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md text-lg font-medium z-40"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) => {
                  return item.submenus ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenus.map((subitem) => {
                              return (
                                <Link
                                  key={subitem.name}
                                  href={subitem.href}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 z-30"
                                  onClick={() => handleLinkClick(close)}
                                >
                                  {subitem.name}
                                </Link>
                              );
                            })}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
